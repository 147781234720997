<template>
    <div class="px-[1rem] lg:px-[0rem] mt-[1rem]">
        <form @submit.prevent="handleFormSubmit" ref="myForm">
            <div class="relative w-full">
                <label for="type" class="block mb-2 text-[12px] font-bold text-[rgba(0,0,0,0.5)]">TYPE</label>
                <select id="type"
                    class="rounded-[16px] block p-2.5 w-full text-[14px] appearance-none bg-transparent h-[46px] border border-[#DADADA] focus:outline-none focus:ring-0 focus:border-[#2D69F6] peer"
                    required v-model="form.type">
                    <option value="">Select</option>
                    <option>Driver</option>
                    <option>Vehicle</option>
                    <option>Trailer</option>
                    <option>Maintenance</option>
                    <option>Insurance</option>
                    <option>Inspection</option>
                    <option>Accident</option>
                    <option>Load</option>
                    <option>Dot Inspection</option>
                    <option>Other</option>
                </select>
                <div class="absolute top-[30px] bottom-[0px] right-[0.5rem] flex items-center pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500" fill="none" stroke="currentColor" stroke-width="2"
                        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </div>
            </div>
            <div class="mt-5">
                <label for="notes" class="block mb-2 text-[12px] font-bold text-[rgba(0,0,0,0.5)]">NOTES</label>
                <textarea id="notes" rows="4" v-model="form.notes"
                    class="rounded-[16px] resize-none block p-2.5 w-full text-[14px] appearance-none bg-transparent border border-[#DADADA] focus:outline-none focus:ring-0 focus:border-[#2D69F6] peer"
                    placeholder="Type notes..."></textarea>
            </div>
            <div class="mt-5" v-if="imageUrl">
                <img v-if="imageUrl" :src="imageUrl" width="48" height="48" />
            </div>
            <div class="mt-5" v-if="!imageUrl">
                <input ref="fileInput" class="hidden" type="file" accept="image/*,application/pdf" @change="handleFile" />
                <button type="button"
                    class="text-[#fff] h-[56px] text-[14px] flex justify-center w-full rounded-[12px] bg-[#2D69F6] items-center mt-3"
                    @click="handleFormSubmit">Add
                    Images or Document</button>
                <button @click="$router.push('/')" type="button"
                    class="text-[#2D69F6] h-[56px] text-[14px] flex justify-center w-full rounded-[12px] bg-[#E8E9ED] items-center mt-3">Cancel</button>
            </div>
            <div class="mt-5" v-if="imageUrl">
                <button type="button"
                    class="text-[#fff] h-[56px] text-[14px] flex justify-center w-full rounded-[12px] bg-[#2D69F6] items-center mt-3"
                    @click="handleDocSubmit">Send</button>
                <button type="button"
                    class="text-[#fff] h-[56px] text-[14px] flex justify-center w-full rounded-[12px] bg-[#2D69F6] items-center mt-3"
                    @click="resetFile">Reset</button>
            </div>
        </form>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "FileDrop",
    data() {
        return {
            imageUrl: null,
            form: {
                type: '',
                notes: '',
                file: null
            }
        }
    },
    methods: {
        ...mapActions(['uploadFileDropDoc', 'setLoader', 'setNotification']),
        async handleDocSubmit(){
            try{
                this.setLoader(true)
                const form = new FormData()
                form.append('type', this.form.type)
                form.append('note', this.form.notes)
                form.append('document', this.form.file)
                await this.uploadFileDropDoc(form)
                this.setNotification({
                    msg: `Document Uploaded successfully.`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
                this.$router.push("/")
            }
            catch(e){
                this.setLoader(false)
                console.log(e)
            }
        },
        handleFormSubmit() {
            const form = this.$refs.myForm;
            if (!form.checkValidity()) {
                form.reportValidity();
            } else {
                this.$refs.fileInput.click();
            }
        },
        handleFile(event) {
            const file = event.target.files[0];
            if (file) {
                this.form.file = file;
                if (file.type === "application/pdf") {
                    this.imageUrl = "/pdf.png";
                } else {
                    this.imageUrl = URL.createObjectURL(file);
                }
            }
        },
        resetFile() {
            this.imageUrl = null
            this.form.file = null
        }
    }
}
</script>