<template>
    <div>
        <Header />
        <div class="w-full mx-auto mt-[5rem] max-w-[640px]" v-if="user">
            <router-view />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Header from "@/components/Header"
export default {
    name: "WebsiteComponent",
    components: { Header },
    computed: {
        ...mapGetters(["user", "refreshApp"])
    },
    watch: {
        refreshApp() {
            if (this.refreshApp) {
                this.initializeApp()
                this.setAppStatus(false)
            }
        }
    },
    methods: {
        ...mapActions(["getUserByID", 'setLoader', "getAllTags", "getAllStates", "getEquipmentData", "getCompany", "setAppStatus"]),
        async initializeApp() {
            this.setLoader(true)
            try {
                await this.getUserByID()
                if (this.user?.team_member?.default_vehicle) await this.getEquipmentData({ id: this.user?.team_member?.default_vehicle?.id, companyID: this.user.team_member.member_company.id, type: 'vehicle' })
                if (this.user?.team_member?.default_trailer) await this.getEquipmentData({ id: this.user?.team_member?.default_trailer?.id, companyID: this.user.team_member.member_company.id, type: 'trailer' })
                await this.getCompany(this.user.team_member.member_company.id)
                await this.getAllTags(this.user.team_member.member_company.id)
                await this.getAllStates()
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)
                console.log(e)
            }
        }
    },
    async mounted() {
        this.initializeApp()
    }
}
</script>